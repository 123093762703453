import react from "react"

export const dayCampBulletsClassic = [
  "Outdoor discovery, games, activity, and fun!",
  "Indoor ice skating with kid-themed music",
  "Woodland survival skills",
  "Pony rides*",
  "Seasonal activities and crafts",
  "Lunch & two snacks",
]
export const dayCampBulletsHockey = [
  "One on-ice skills and skating development session (1.5 hours), including a full-ice scrimmage",
  "Outdoor discovery, games, and sports",
  "Indoor ice skating with kid-themed music**",
  "Woodland survival skills",
  "Pony rides*",
  "Seasonal activities and crafts",
  "Lunch & two snacks",
]
